import React from 'react'
import './AppMob.css';

import im01 from "../src/imagesMob/mob_01.jpg";
import im02 from "../src/imagesMob/mob_02.jpg";
import im03 from "../src/imagesMob/mob_03.jpg";
import im04 from "../src/imagesMob/mob_04.jpg";
import im05 from "../src/imagesMob/mob_05.jpg";
import im06 from "../src/imagesMob/mob_06.jpg";
import im07 from "../src/imagesMob/mob_07.jpg";
import im08 from "../src/imagesMob/mob_08.jpg";
import im09 from "../src/imagesMob/mob_09.jpg";
import im10 from "../src/imagesMob/mob_10.jpg";
import im11 from "../src/imagesMob/mob_11.jpg";
import im12 from "../src/imagesMob/mob_12.jpg";
import im13 from "../src/imagesMob/mob_13.jpg";
import im14 from "../src/imagesMob/mob_14.jpg";
import im15 from "../src/imagesMob/mob_15.jpg";
import im16 from "../src/imagesMob/mob_16.jpg";
import im17 from "../src/imagesMob/mob_17.jpg";
import im18 from "../src/imagesMob/mob_18.jpg";
import im19 from "../src/imagesMob/mob_19.jpg";
import im20 from "../src/imagesMob/mob_20.jpg";
import im21 from "../src/imagesMob/mob_21.jpg";
import im22 from "../src/imagesMob/mob_22.jpg";
import im23 from "../src/imagesMob/mob_23.jpg";
import im24 from "../src/imagesMob/mob_24.jpg";
import im25 from "../src/imagesMob/mob_25.jpg";
import im26 from "../src/imagesMob/mob_26.jpg";
import im27 from "../src/imagesMob/mob_27.jpg";
import im28 from "../src/imagesMob/mob_28.jpg";
import im29 from "../src/imagesMob/mob_29.jpg";
import im30 from "../src/imagesMob/mob_30.jpg";
import im31 from "../src/imagesMob/mob_31.jpg";
import im32 from "../src/imagesMob/mob_32.jpg";
import im33 from "../src/imagesMob/mob_33.jpg";
import im34 from "../src/imagesMob/mob_34.jpg";
import im35 from "../src/imagesMob/mob_35.jpg";
import im36 from "../src/imagesMob/mob_36.jpg";
import im37 from "../src/imagesMob/mob_37.jpg";
import im38 from "../src/imagesMob/mob_38.jpg";
import im39 from "../src/imagesMob/mob_39.jpg";
import im40 from "../src/imagesMob/mob_40.jpg";
import im41 from "../src/imagesMob/mob_41.jpg";
import im42 from "../src/imagesMob/mob_42.jpg";
import im43 from "../src/imagesMob/mob_43.jpg";
import im44 from "../src/imagesMob/mob_44.jpg";
import im45 from "../src/imagesMob/mob_45.jpg";
import im46 from "../src/imagesMob/mob_46.jpg";
import im47 from "../src/imagesMob/mob_47.jpg";
import im48 from "../src/imagesMob/mob_48.jpg";
import im49 from "../src/imagesMob/mob_49.jpg";
import im50 from "../src/imagesMob/mob_50.jpg";
import im51 from "../src/imagesMob/mob_51.jpg";
import im52 from "../src/imagesMob/mob_52.jpg";
import im53 from "../src/imagesMob/mob_53.jpg";
import im54 from "../src/imagesMob/mob_54.jpg";
import im55 from "../src/imagesMob/mob_55.jpg";
import im56 from "../src/imagesMob/mob_56.jpg";
import im57 from "../src/imagesMob/mob_57.jpg";
import im58 from "../src/imagesMob/mob_58.jpg";
import im59 from "../src/imagesMob/mob_59.jpg";
import im60 from "../src/imagesMob/mob_60.jpg";
import im61 from "../src/imagesMob/mob_61.jpg";
import im62 from "../src/imagesMob/mob_62.jpg";

import data from "./data.json";
import WinM from "./Components/Window/WindowMobile";
function Mobile() {
    return (
        <div className="ixy">

            <div className="mobile d-block d-sm-none">
                <div className="Table_02">
                    <div className="mob-01">
                        <img loading="lazy" src={im01} width="374" height="277" alt="" />
                    </div>
                    <div className="mob-02">
                        <img loading="lazy" src={im02} width="27" height="7655" alt="" />
                    </div>
                    <div className="mob-03">
                        <WinM
                            className={`done1 tuile`}
                            title={data.day1.title}
                            id={data.day1.id}
                            url={data.day1.url}
                            pdfUrl={data.day1.pdfurl}
                        />
                    </div>
                    <div className="mob-04">
                        <img loading="lazy" src={im04} width="29" height="322" alt="" />
                    </div>
                    <div className="mob-05">
                        <img loading="lazy" src={im05} width="318" height="121" alt="" />

                    </div>
                    <div className="mob-06">
                        <img loading="lazy" src={im06} width="2" height="901" alt="" />
                    </div>
                    <div className="mob-07">
                        <WinM
                            className={`done2 tuile`}
                            title={data.day2.title}
                            id={data.day2.id}
                            url={data.day2.url}
                            pdfUrl={data.day2.pdfurl}
                        />
                    </div>
                    <div className="mob-08">
                        <img loading="lazy" src={im08} width="27" height="7333" alt="" />
                    </div>
                    <div className="mob-09">
                        <img loading="lazy" src={im09} width="318" height="39" alt="" />
                    </div>
                    <div className="mob-10">
                        <WinM
                            className={`done3 tuile`}
                            title={data.day3.title}
                            id={data.day3.id}
                            url={data.day3.url}
                            pdfUrl={data.day3.pdfurl}
                        />
                    </div>
                    <div className="mob-11">
                        <img loading="lazy" src={im11} width="318" height="95" alt="" />
                    </div>
                    <div className="mob-12">
                        <WinM
                            className={`done4 tuile`}
                            title={data.day4.title}
                            id={data.day4.id}
                            url={data.day4.url}
                            pdfUrl={data.day4.pdfurl}
                        />
                    </div>
                    <div className="mob-13">
                        <img loading="lazy" src={im13} width="1" height="365" alt="" />
                    </div>
                    <div className="mob-14">
                        <img loading="lazy" src={im14} width="317" height="164" alt="" />
                    </div>
                    <div className="mob-15">
                        <img loading="lazy" src={im15} width="1" height="6690" alt="" />
                    </div>
                    <div className="mob-16">
                        <WinM
                            className={`done5 tuile`}
                            title={data.day5.title}
                            id={data.day5.id}
                            url={data.day5.url}
                            pdfUrl={data.day5.pdfurl}
                        />
                    </div>
                    <div className="mob-17">
                        <img loading="lazy" src={im17} width="319" height="60" alt="" />
                    </div>
                    <div className="mob-18">
                        <img loading="lazy" src={im18} width="1" height="6428" alt="" />
                    </div>
                    <div className="mob-19">
                        <WinM
                            className={`done6 tuile`}
                            title={data.day6.title}
                            id={data.day6.id}
                            url={data.day6.url}
                            pdfUrl={data.day6.pdfurl}
                        />
                    </div>
                    <div className="mob-20">
                        <img loading="lazy" src={im20} width="1" height="285" alt="" />
                    </div>
                    <div className="mob-21">
                        <img loading="lazy" src={im21} width="317" height="84" alt="" />
                    </div>
                    <div className="mob-22">
                        <WinM
                            className={`done7 tuile`}
                            title={data.day7.title}
                            id={data.day7.id}
                            url={data.day7.url}
                            pdfUrl={data.day7.pdfurl}
                        />
                    </div>
                    <div className="mob-23">
                        <img loading="lazy" src={im23} width="318" height="39" alt="" />
                    </div>
                    <div className="mob-24">
                        <WinM
                            className={`done8 tuile`}
                            title={data.day8.title}
                            id={data.day8.id}
                            url={data.day8.url}
                            pdfUrl={data.day8.pdfurl}
                        />
                    </div>
                    <div className="mob-25">
                        <img loading="lazy" src={im25} width="318" height="211" alt="" />
                    </div>
                    <div className="mob-26">
                        <WinM
                            className={`done9 tuile`}
                            title={data.day9.title}
                            id={data.day9.id}
                            url={data.day9.url}
                            pdfUrl={data.day9.pdfurl}
                        />
                    </div>
                    <div className="mob-27">
                        <img loading="lazy" src={im27} width="318" height="60" alt="" />
                    </div>
                    <div className="mob-28">
                        <WinM
                            className={`done10 tuile`}
                            title={data.day10.title}
                            id={data.day10.id}
                            url={data.day10.url}
                            pdfUrl={data.day10.pdfurl}
                        />
                    </div>
                    <div className="mob-29">
                        <img loading="lazy" src={im29} width="318" height="122" alt="" />
                    </div>
                    <div className="mob-30">
                        <WinM
                            className={`done11 tuile`}
                            title={data.day11.title}
                            id={data.day11.id}
                            url={data.day11.url}
                            pdfUrl={data.day11.pdfurl}
                        />
                    </div>
                    <div className="mob-31">
                        <img loading="lazy" src={im31} width="318" height="40" alt="" />
                    </div>
                    <div className="mob-32">
                        <WinM
                            className={`done12 tuile`}
                            title={data.day12.title}
                            id={data.day12.id}
                            url={data.day12.url}
                            pdfUrl={data.day12.pdfurl}
                        />
                    </div>
                    <div className="mob-33">
                        <img loading="lazy" src={im33} width="1" height="295" alt="" />
                    </div>
                    <div className="mob-34">
                        <img loading="lazy" src={im34} width="317" height="94" alt="" />
                    </div>
                    <div className="mob-35">
                        <WinM
                            className={`done13 tuile`}
                            title={data.day13.title}
                            id={data.day13.id}
                            url={data.day13.url}
                            pdfUrl={data.day13.pdfurl}
                        />
                    </div>
                    <div className="mob-36">
                        <img loading="lazy" src={im36} width="318" height="164" alt="" />
                    </div>
                    <div className="mob-37">
                        <WinM
                            className={`done14 tuile`}
                            title={data.day14.title}
                            id={data.day14.id}
                            url={data.day14.url}
                            pdfUrl={data.day14.pdfurl}
                        />
                    </div>
                    <div className="mob-38">
                        <img loading="lazy" src={im38} width="318" height="60" alt="" />
                    </div>
                    <div className="mob-39">
                        <WinM
                            className={`done15 tuile`}
                            title={data.day15.title}
                            id={data.day15.id}
                            url={data.day15.url}
                            pdfUrl={data.day15.pdfurl}
                        />
                    </div>
                    <div className="mob-40">
                        <img loading="lazy" src={im40} width="318" height="84" alt="" />
                    </div>
                    <div className="mob-41">
                        <WinM
                            className={`done16 tuile`}
                            title={data.day16.title}
                            id={data.day16.id}
                            url={data.day16.url}
                            pdfUrl={data.day16.pdfurl}
                        />
                    </div>
                    <div className="mob-42">
                        <img loading="lazy" src={im42} width="318" height="40" alt="" />
                    </div>
                    <div className="mob-43">
                        <WinM
                            className={`done17 tuile`}
                            title={data.day17.title}
                            id={data.day17.id}
                            url={data.day17.url}
                            pdfUrl={data.day17.pdfurl}
                        />
                    </div>
                    <div className="mob-44">
                        <img loading="lazy" src={im44} width="318" height="213" alt="" />
                    </div>
                    <div className="mob-45">
                        <WinM
                            className={`done18 tuile`}
                            title={data.day18.title}
                            id={data.day18.id}
                            url={data.day18.url}
                            pdfUrl={data.day18.pdfurl}
                        />
                    </div>
                    <div className="mob-46">
                        <img loading="lazy" src={im46} width="318" height="58" alt="" />
                    </div>
                    <div className="mob-47">
                        <WinM
                            className={`done19 tuile`}
                            title={data.day19.title}
                            id={data.day19.id}
                            url={data.day19.url}
                            pdfUrl={data.day19.pdfurl}
                        />
                    </div>
                    <div className="mob-48">
                        <img loading="lazy" src={im48} width="318" height="32" alt="" />
                    </div>
                    <div className="mob-49">
                        <WinM
                            className={`done20 tuile`}
                            title={data.day20.title}
                            id={data.day20.id}
                            url={data.day20.url}
                            pdfUrl={data.day20.pdfurl}
                        />
                    </div>
                    <div className="mob-50">
                        <img loading="lazy" src={im50} width="318" height="60" alt="" />
                    </div>
                    <div className="mob-51">
                        <WinM
                            className={`done21 tuile`}
                            title={data.day21.title}
                            id={data.day21.id}
                            url={data.day21.url}
                            pdfUrl={data.day21.pdfurl}
                        />
                    </div>
                    <div className="mob-52">
                        <img loading="lazy" src={im52} width="318" height="123" alt="" />
                    </div>
                    <div className="mob-53">
                        <WinM
                            className={`done22 tuile`}
                            title={data.day22.title}
                            id={data.day22.id}
                            url={data.day22.url}
                            pdfUrl={data.day22.pdfurl}
                        />
                    </div>
                    <div className="mob-54">
                        <img loading="lazy" src={im54} width="318" height="34" alt="" />
                    </div>
                    <div className="mob-55">
                        <WinM
                            className={`done23 tuile`}
                            title={data.day23.title}
                            id={data.day23.id}
                            url={data.day23.url}
                            pdfUrl={data.day23.pdfurl}
                        />
                    </div>
                    <div className="mob-56">
                        <img loading="lazy" src={im56} width="318" height="165" alt="" />
                    </div>
                    <div className="mob-57">
                        <WinM
                            className={`done24 tuile`}
                            title={data.day24.title}
                            id={data.day24.id}
                            url={data.day24.url}
                            pdfUrl={data.day24.pdfurl}
                        />
                    </div>
                    <div className="mob-58">
                        <img loading="lazy" src={im58} width="318" height="58" alt="" />
                    </div>
                    <div className="mob-59">
                        <WinM
                            className={`done25 tuile`}
                            title={data.day25.title}
                            id={data.day25.id}
                            url={data.day25.url}
                            pdfUrl={data.day25.pdfurl}
                        />
                    </div>
                    <div className="mob-60">
                        <img loading="lazy" src={im60} width="318" height="200" alt="" />
                    </div>
                    <div className="mob-61">
                        <WinM
                            className={`done26 tuile`}
                            title={data.day26.title}
                            id={data.day26.id}
                            url={data.day26.url}
                            pdfUrl={data.day26.pdfurl}
                        />
                    </div>
                    <div className="mob-62">
                        <img loading="lazy" src={im62} width="374" height="258" alt="" />
                    </div>
                    <a target="_blank" href="https://mv.millionnaire-visionnaire.com/">
                        <button className="btn5 btnStyle shadow">{data.button}</button>
                        <button className="btn14 btnStyle shadow">{data.button}</button>
                        <button className="btn25 btnStyle shadow">{data.button}</button>
                        <button className="btn29 btnStyle shadow">{data.button}</button>
                        <button className="btn36 btnStyle shadow">{data.button}</button>
                        <button className="btn44 btnStyle shadow">{data.button}</button>
                        <button className="btn56 btnStyle shadow">{data.button}</button>
                        <button className="btn60 btnStyle shadow">{data.button}</button>
                    </a>

                    <span className="textVoici d-none">Voici 26 vidéos offertes pour avancer sur le Chemin de la Liberté financière</span>
                </div>

            </div>
        </div >
    )
}

export default Mobile
