import logo from './logo.svg';
import './App.css';

import './Button.scss'
import square from "../src/images/bRes_07.jpg";
import euro from "../src/images/euro.png";

import i1 from "../src/images/bRes_01.jpg";
import i2 from "../src/images/bRes_02.jpg";
import i3 from "../src/images/bRes_03.jpg";
import i4 from "../src/images/bRes_04.jpg";
import i5 from "../src/images/bRes_05.jpg";
import i6 from "../src/images/bRes_06.jpg";
import i7 from "../src/images/bRes_07.jpg";
import i8 from "../src/images/bRes_08.jpg";
import i9 from "../src/images/bRes_09.jpg";
import i10 from "../src/images/bRes_10.jpg";
import i11 from "../src/images/bRes_11.jpg";
import i12 from "../src/images/bRes_12.jpg";
import i13 from "../src/images/bRes_13.jpg";
import i14 from "../src/images/bRes_14.jpg";
import i15 from "../src/images/bRes_15.jpg";
import i16 from "../src/images/bRes_16.jpg";
import i17 from "../src/images/bRes_17.jpg";
import i18 from "../src/images/bRes_18.jpg";
import i19 from "../src/images/bRes_19.jpg";
import i20 from "../src/images/bRes_20.jpg";
import i21 from "../src/images/bRes_21.jpg";
import i22 from "../src/images/bRes_22.jpg";
import i23 from "../src/images/bRes_23.jpg";
import i24 from "../src/images/bRes_24.jpg";
import i25 from "../src/images/bRes_25.jpg";
import i26 from "../src/images/bRes_26.jpg";
import i27 from "../src/images/bRes_27.jpg";
import i28 from "../src/images/bRes_28.jpg";
import i29 from "../src/images/bRes_29.jpg";
import i30 from "../src/images/bRes_30.jpg";
import i31 from "../src/images/bRes_31.jpg";
import i32 from "../src/images/bRes_32.jpg";
import i33 from "../src/images/bRes_33.jpg";
import i34 from "../src/images/bRes_34.jpg";
import i35 from "../src/images/bRes_35.jpg";
import i36 from "../src/images/bRes_36.jpg";
import i37 from "../src/images/bRes_37.jpg";
import i38 from "../src/images/bRes_38.jpg";
import i39 from "../src/images/bRes_39.jpg";
import i40 from "../src/images/bRes_40.jpg";
import i41 from "../src/images/bRes_41.jpg";
import i42 from "../src/images/bRes_42.jpg";
import i43 from "../src/images/bRes_43.jpg";
import i44 from "../src/images/bRes_44.jpg";
import i45 from "../src/images/bRes_45.jpg";
import i46 from "../src/images/bRes_46.jpg";
import i47 from "../src/images/bRes_47.jpg";
import i48 from "../src/images/bRes_48.jpg";
import i49 from "../src/images/bRes_49.jpg";
import i50 from "../src/images/bRes_50.jpg";
import i51 from "../src/images/bRes_51.jpg";
import i52 from "../src/images/bRes_52.jpg";
import i53 from "../src/images/bRes_53.jpg";
import i54 from "../src/images/bRes_54.jpg";
import i55 from "../src/images/bRes_55.jpg";
import i56 from "../src/images/bRes_56.jpg";
import i57 from "../src/images/bRes_57.jpg";
import i58 from "../src/images/bRes_58.jpg";
import i59 from "../src/images/bRes_59.jpg";
import i60 from "../src/images/bRes_60.jpg";
import i61 from "../src/images/bRes_61.jpg";
import i62 from "../src/images/bRes_62.jpg";
import i63 from "../src/images/bRes_63.jpg";
import i64 from "../src/images/bRes_64.jpg";
import i65 from "../src/images/bRes_65.jpg";
import i66 from "../src/images/bRes_66.jpg";
import i67 from "../src/images/bRes_67.jpg";
import i68 from "../src/images/bRes_68.jpg";
import i69 from "../src/images/bRes_69.jpg";
import i70 from "../src/images/bRes_70.jpg";
import i71 from "../src/images/bRes_71.jpg";
import i72 from "../src/images/bRes_72.jpg";
import i73 from "../src/images/bRes_73.jpg";
import i74 from "../src/images/bRes_74.jpg";
import i75 from "../src/images/bRes_75.jpg";
import i76 from "../src/images/bRes_76.jpg";
import i77 from "../src/images/bRes_77.jpg";
import i78 from "../src/images/bRes_78.jpg";
import i79 from "../src/images/bRes_79.jpg";
import i80 from "../src/images/bRes_80.jpg";
import i81 from "../src/images/bRes_81.jpg";
import i82 from "../src/images/bRes_82.jpg";
import i83 from "../src/images/bRes_83.jpg";
import i84 from "../src/images/bRes_84.jpg";
import i85 from "../src/images/bRes_85.jpg";
import i86 from "../src/images/bRes_86.jpg";
import i87 from "../src/images/bRes_87.jpg";
import i88 from "../src/images/bRes_88.jpg";
import i89 from "../src/images/bRes_89.jpg";
import i90 from "../src/images/bRes_90.jpg";
import i91 from "../src/images/bRes_91.jpg";
import i92 from "../src/images/bRes_92.jpg";
import i93 from "../src/images/bRes_93.jpg";
import i94 from "../src/images/bRes_94.jpg";
import i95 from "../src/images/bRes_95.jpg";
import i96 from "../src/images/bRes_96.jpg";
import i97 from "../src/images/bRes_97.jpg";
import i98 from "../src/images/bRes_98.jpg";
import i99 from "../src/images/bRes_99.jpg";
import i100 from "../src/images/bRes_100.png";


import MobileView from './Mobile'





import Win from "./Components/Window/Window";


import data from "./data.json";
import lo from "../src/images/logo1.png";
import Particles from "react-particles-js";
import { CountDownDaily } from '@nilevia/count-down-timer-react'


function App() {
  const daty = new Date();
  const dateLimit = new Date("2021-01-31T23:59:59+01:00");

  return (
    <div className="">
      <div className="d-none d-sm-block">
        <div className="izy_rehetra ">
          <div className="Table_01">
            <div className="bRes-01">
              <img loading="lazy" src={i1} width="1519" height="388" alt="" />
            </div>
            <div className="bRes-02">
              <a target="_blank" href="https://mv.millionnaire-visionnaire.com/">  <img loading="lazy" className="img  logoCDV d-none" src={lo} width="auto" height="auto" alt="" />
                <button className="btn btn_02 shadow">{data.button}</button></a>
              <img loading="lazy" src={i2} width="949" height="513" alt="" />
            </div>
            <div className="bRes-03">
              <Win
                className={`done1  tuile`}
                title={data.day1.title}
                id={data.day1.id}
                url={data.day1.url}
                pdfUrl={data.day1.pdfurl}
              />
            </div>
            <div className="bRes-04">
              <img loading="lazy" src={i4} width="126" height="2517" alt="" />
            </div>
            <div className="bRes-05">
              <img loading="lazy" src={i5} width="444" height="229" alt="" />
            </div>
            <div className="bRes-06">
              <img loading="lazy" src={i6} width="242" height="400" alt="" />
            </div>
            <div className="bRes-07">
              <Win
                className={`done3  tuile`}
                title={data.day3.title}
                id={data.day3.id}
                url={data.day3.url}
                pdfUrl={data.day3.pdfurl}
              />
            </div>
            <div className="bRes-08">
              <img loading="lazy" src={i8} width="257" height="617" alt="" />
            </div>
            <div className="bRes-09">
              <Win
                className={`done2  tuile`}
                title={data.day2.title}
                id={data.day2.id}
                url={data.day2.url}
                pdfUrl={data.day2.pdfurl}
              />
            </div>
            <div className="bRes-10">
              <img loading="lazy" src={i10} width="6" height="2004" alt="" />
            </div>
            <div className="bRes-11">
              <img loading="lazy" src={i11} width="444" height="116" alt="" />
            </div>
            <div className="bRes-12 ">

              <img loading="lazy" src={i12} width="444" height="333" alt="" />
            </div>
            <div className="bRes-13">
              <img loading="lazy" src={i13} width="148" height="1076" alt="" />
            </div>
            <div className="bRes-14">
              <Win
                className={`done4  tuile`}
                title={data.day4.title}
                id={data.day4.id}
                url={data.day4.url}
                pdfUrl={data.day4.pdfurl}
              />
            </div>
            <div className="bRes-15">
              <img loading="lazy" src={i15} width="94" height="699" alt="" />
            </div>
            <div className="bRes-16">
              <img loading="lazy" src={i16} width="249" height="1071" alt="" />
            </div>
            <div className="bRes-17">
              <Win
                className={`done5  tuile`}
                title={data.day5.title}
                id={data.day5.id}
                url={data.day5.url}
                pdfUrl={data.day5.pdfurl}
              />
            </div>
            <div className="bRes-18">
              <img loading="lazy" src={i18} width="8" height="1387" alt="" />
            </div>
            <div className="bRes-19">
              <img loading="lazy" src={i19} width="444" height="415" alt="" />
            </div>
            <div className="bRes-20">
              <img loading="lazy" src={i20} width="444" height="198" alt="" />
            </div>
            <div className="bRes-21">
              <img loading="lazy" src={i21} width="75" height="377" alt="" />
            </div>
            <div className="bRes-22">
              <Win
                className={`done7 tuile`}
                title={data.day7.title}
                id={data.day7.id}
                url={data.day7.url}
                pdfUrl={data.day7.pdfurl}
              />
            </div>
            <div className="bRes-23">
              <img loading="lazy" src={i23} width="19" height="589" alt="" />
            </div>
            <div className="bRes-24">
              <Win
                className={`done6 tuile`}
                title={data.day6.title}
                id={data.day6.id}
                url={data.day6.url}
                pdfUrl={data.day6.pdfurl}
              />
            </div>
            <div className="bRes-25">
              <img loading="lazy" src={i25} width="444" height="93" alt="" />
            </div>
            <div className="bRes-26">
              <img loading="lazy" src={i26} width="444" height="305" alt="" />
            </div>
            <div className="bRes-27">
              <img loading="lazy" src={i27} width="59" height="5384" alt="" />
            </div>
            <div className="bRes-28">
              <Win
                className={`done8 tuile`}
                title={data.day8.title}
                id={data.day8.id}
                url={data.day8.url}
                pdfUrl={data.day8.pdfurl}
              />
            </div>
            <div className="bRes-29">
              <img loading="lazy" src={i29} width="164" height="212" alt="" />
            </div>
            <div className="bRes-30">
              <img loading="lazy" src={i30} width="93" height="411" alt="" />
            </div>
            <div className="bRes-31">
              <Win
                className={`done9 tuile`}
                title={data.day9.title}
                id={data.day9.id}
                url={data.day9.url}
                pdfUrl={data.day9.pdfurl}
              />
            </div>
            <div className="bRes-32">
              <img loading="lazy" src={i32} width="339" height="316" alt="" />
            </div>
            <div className="bRes-33">

              <img loading="lazy" src={i33} width="444" height="339" alt="" />

            </div>
            <div className="bRes-34">
              <img loading="lazy" src={i34} width="444" height="32" alt="" />
            </div>
            <div className="bRes-35">
              <img loading="lazy" src={i35} width="437" height="95" alt="" />
            </div>
            <div className="bRes-36">
              <Win
                className={`done10 tuile`}
                title={data.day10.title}
                id={data.day10.id}
                url={data.day10.url}
                pdfUrl={data.day10.pdfurl}
              />
            </div>
            <div className="bRes-37">
              <img loading="lazy" src={i37} width="42" height="4856" alt="" />
            </div>
            <div className="bRes-38">
              <img loading="lazy" src={i38} width="337" height="391" alt="" />
            </div>
            <div className="bRes-39">
              <Win
                className={`done11 tuile`}
                title={data.day11.title}
                id={data.day11.id}
                url={data.day11.url}
                pdfUrl={data.day11.pdfurl}
              />
            </div>
            <div className="bRes-40">
              <img loading="lazy" src={i40} width="193" height="421" alt="" />
            </div>
            <div className="bRes-41">
              <img loading="lazy" src={i41} width="444" height="232" alt="" />
            </div>
            <div className="bRes-42">
              <img loading="lazy" src={i42} width="444" height="107" alt="" />
            </div>
            <div className="bRes-43">
              <img loading="lazy" src={i43} width="14" height="4370" alt="" />
            </div>
            <div className="bRes-44">
              <Win
                className={`done12 tuile`}
                title={data.day12.title}
                id={data.day12.id}
                url={data.day12.url}
                pdfUrl={data.day12.pdfurl}
              />
            </div>
            <div className="bRes-45">
              <img loading="lazy" src={i45} width="323" height="30" alt="" />
            </div>
            <div className="bRes-46">
              <img loading="lazy" src={i46} width="300" height="349" alt="" />
            </div>
            <div className="bRes-47">
              <Win
                className={`done13 tuile`}
                title={data.day13.title}
                id={data.day13.id}
                url={data.day13.url}
                pdfUrl={data.day13.pdfurl}
              />
            </div>
            <div className="bRes-48">
              <img loading="lazy" src={i48} width="216" height="737" alt="" />
            </div>
            <div className="bRes-49">
              <img loading="lazy" src={i49} width="444" height="95" alt="" />
            </div>
            <div className="bRes-50">
              <img loading="lazy" src={i50} width="444" height="65" alt="" />
            </div>
            <div className="bRes-51">
              <img loading="lazy" src={i51} width="396" height="385" alt="" />
            </div>
            <div className="bRes-52">
              <Win
                className={`done14 tuile`}
                title={data.day14.title}
                id={data.day14.id}
                url={data.day14.url}
                pdfUrl={data.day14.pdfurl}
              />
            </div>

            <div className="bRes-53">

              <img loading="lazy" src={i53} width="348" height="388" alt="" />
            </div>
            <div className="bRes-54">
              <img loading="lazy" src={i54} width="444" height="101" alt="" />
            </div>
            <div className="bRes-55">
              <img loading="lazy" src={i55} width="44" height="508" alt="" />
            </div>
            <div className="bRes-56">
              <Win
                className={`done15 tuile`}
                title={data.day15.title}
                id={data.day15.id}
                url={data.day15.url}
                pdfUrl={data.day15.pdfurl}
              />
            </div>
            <div className="bRes-57">
              <img loading="lazy" src={i57} width="352" height="3" alt="" />
            </div>
            <div className="bRes-58">
              <img loading="lazy" src={i58} width="281" height="974" alt="" />
            </div>
            <div className="bRes-59">
              <Win
                className={`done16 tuile`}
                title={data.day16.title}
                id={data.day16.id}
                url={data.day16.url}
                pdfUrl={data.day16.pdfurl}
              />
            </div>
            <div className="bRes-60">
              <img loading="lazy" src={i60} width="191" height="395" alt="" />
            </div>
            <div className="bRes-61">
              <img loading="lazy" src={i61} width="444" height="224" alt="" />
            </div>
            <div className="bRes-62">
              <img loading="lazy" src={i62} width="444" height="111" alt="" />
            </div>
            <div className="bRes-63">
              <img loading="lazy" src={i63} width="119" height="579" alt="" />
            </div>
            <div className="bRes-64">
              <Win
                className={`done17 tuile`}
                title={data.day17.title}
                id={data.day17.id}
                url={data.day17.url}
                pdfUrl={data.day17.pdfurl}
              />
            </div>
            <div className="bRes-65">
              <img loading="lazy" src={i65} width="72" height="752" alt="" />
            </div>
            <div className="bRes-66">
              <img loading="lazy" src={i66} width="29" height="1287" alt="" />
            </div>
            <div className="bRes-67">
              <Win
                className={`done18 tuile`}
                title={data.day18.title}
                id={data.day18.id}
                url={data.day18.url}
                pdfUrl={data.day18.pdfurl}
              />
            </div>
            <div className="bRes-68">
              <img loading="lazy" src={i68} width="15" height="469" alt="" />
            </div>
            <div className="bRes-69">
              <img loading="lazy" src={i69} width="444" height="468" alt="" />
            </div>
            <div className="bRes-70">
              <img loading="lazy" src={i70} width="444" height="185" alt="" />
            </div>
            <div className="bRes-71">
              <img loading="lazy" src={i71} width="318" height="818" alt="" />
            </div>
            <div className="bRes-72">
              <Win
                className={`done19 tuile`}
                title={data.day19.title}
                id={data.day19.id}
                url={data.day19.url}
                pdfUrl={data.day19.pdfurl}
              />
            </div>
            <div className="bRes-73">
              <img loading="lazy" src={i73} width="97" height="440" alt="" />
            </div>
            <div className="bRes-74">
              <img loading="lazy" src={i74} width="39" height="769" alt="" />
            </div>
            <div className="bRes-75">
              <Win
                className={`done20 tuile`}
                title={data.day20.title}
                id={data.day20.id}
                url={data.day20.url}
                pdfUrl={data.day20.pdfurl}
              />
            </div>
            <div className="bRes-76">
              <img loading="lazy" src={i76} width="33" height="2456" alt="" />
            </div>
            <div className="bRes-77">
              <img loading="lazy" src={i77} width="444" height="156" alt="" />
            </div>
            <div className="bRes-78">
              <img loading="lazy" src={i78} width="71" height="378" alt="" />
            </div>
            <div className="bRes-79">
              <Win
                className={`done21 tuile`}
                title={data.day21.title}
                id={data.day21.id}
                url={data.day21.url}
                pdfUrl={data.day21.pdfurl}
              />
            </div>
            <div className="bRes-80">
              <img loading="lazy" src={i80} width="26" height="502" alt="" />
            </div>
            <div className="bRes-81">
              <img loading="lazy" src={i81} width="444" height="485" alt="" />
            </div>
            <div className="bRes-82">
              <img loading="lazy" src={i82} width="444" height="94" alt="" />
            </div>
            <div className="bRes-83">
              <img loading="lazy" src={i83} width="23" height="1811" alt="" />
            </div>
            <div className="bRes-84">
              <Win
                className={`done22 tuile`}
                title={data.day22.title}
                id={data.day22.id}
                url={data.day22.url}
                pdfUrl={data.day22.pdfurl}
              />
            </div>
            <div className="bRes-85">
              <img loading="lazy" src={i85} width="395" height="124" alt="" />
            </div>
            <div className="bRes-86">
              <img loading="lazy" src={i86} width="324" height="827" alt="" />
            </div>
            <div className="bRes-87">
              <Win
                className={`done23 tuile`}
                title={data.day23.title}
                id={data.day23.id}
                url={data.day23.url}
                pdfUrl={data.day23.pdfurl}
              />
            </div>
            <div className="bRes-88">
              <img loading="lazy" src={i88} width="136" height="1687" alt="" />
            </div>
            <div className="bRes-89">
              <img loading="lazy" src={i89} width="444" height="205" alt="" />
            </div>
            <div className="bRes-90">
              <img loading="lazy" src={i90} width="444" height="543" alt="" />
            </div>
            <div className="bRes-91">
              <Win
                className={`done24 tuile`}
                title={data.day24.title}
                id={data.day24.id}
                url={data.day24.url}
                pdfUrl={data.day24.pdfurl}
              />
            </div>
            <div className="bRes-92">
              <img loading="lazy" src={i92} width="444" height="1038" alt="" />
            </div>
            <div className="bRes-93">
              <img loading="lazy" src={i93} width="272" height="576" alt="" />
            </div>
            <div className="bRes-94">
              <Win
                className={`done25 tuile`}
                title={data.day25.title}
                id={data.day25.id}
                url={data.day25.url}
                pdfUrl={data.day25.pdfurl}
              />
            </div>
            <div className="bRes-95">
              <img loading="lazy" src={i95} width="52" height="860" alt="" />
            </div>
            <div className="bRes-96">
              <img loading="lazy" src={i96} width="444" height="292" alt="" />
            </div>
            <div className="bRes-97">
              <img loading="lazy" src={i97} width="13" height="284" alt="" />
            </div>
            <div className="bRes-98">
              <Win
                className={`done26 tuile`}
                title={data.day26.title}
                id={data.day26.id}
                url={data.day26.url}
                pdfUrl={data.day26.pdfurl}
              />
            </div>
            <div className="bRes-99">
              <img loading="lazy" src={i99} width="259" height="284" alt="" />
            </div>
            <div className="bRes-100">
              <img loading="lazy" src={i100} width="1519" height="740
              " alt="" />
            </div>
            <a target="_blank" href="https://mv.millionnaire-visionnaire.com/">
              <button className="btn btn_12 shadow">{data.button}</button>
              <button className="btn btn_33  shadow">{data.button}</button>
              <button className="btn btn_53  shadow">{data.button}</button>
              <button className="btn btn_69  shadow">{data.button}</button>
              <button className="btn btn_89  shadow">{data.button}</button>
              <button className="btn btn_100 shadow">{data.button}</button>
            </a>

          </div>
        </div>
        <div className={`${daty <= dateLimit ? "time" : " d-none"} `}  >
          <p className="time__first">Fin de l'offre d'essai du Cercle des Visionnaires dans
            <CountDownDaily className="count"
              endDate={"2021-01-31T23:59:59+01:00"}
              dayAffix="j"
              hourAffix="h"
              minutesAffix="min"
              secondAffix="sec"
            /></p>
          <a className="bandeau" target="_blank" href="https://mv.millionnaire-visionnaire.com/"><p className="time__second"> Cliquez ICI pour tester le Cercle des Visionnaires pour 7€ pendant 7 jours !</p></a>
        </div>


        <div className={`${daty >= dateLimit ? "d-none" : " d-none"}`}>
          <h1 className="finished__texts">
            L'offre d'essai du Cercle des Visionnaires est terminée ... !
          </h1>

        </div>


      </div>



      <Particles
        params={{
          "particles": {
            "number": {
              "value": 8,
              "density": {
                "enable": true,
                "value_area": 800
              }
            },
            "line_linked": {
              "enable": false
            },
            "move": {
              "speed": 5,
              "out_mode": "out"
            },
            "shape": {
              "type": [
                "image",
                "circle"
              ],
              "image": [
                {
                  "src": euro,
                  "height": 23,
                  "width": 23
                },

              ]
            },
            "color": {
              "value": "#CCC"
            },
            "size": {
              "value": 30,
              "random": false,
              "anim": {
                "enable": true,
                "speed": 4,
                "size_min": 10,
                "sync": false
              }
            }
          },
          "retina_detect": false
        }} />

      <MobileView />
    </div>
  );
}

export default App;
